import Vue from "vue";
import { createPinia, PiniaVuePlugin } from "pinia";
import VueClipboard from "vue-clipboard2";
import App from "@/App.vue";
import router from "@/router";
import store from "@/store";
import ApiClient from "@/api";
import { i18n } from "@/i18n";
import "@/plugins/directives";
import vuetify from "@/plugins/vuetify";
import { vimeo } from "@/config";
import { poinReferrer, poinPartnerCode } from "@/utils/cookie-utils";
import { register } from "swiper/element/bundle";

register();

Vue.config.productionTip = false;

// 레퍼러 및 파트너 코드 저장
poinReferrer.set();
poinPartnerCode.set();

// vimeo
const vimeoApiInstance = new ApiClient(null, { baseURL: vimeo.baseUrl }, [
  "vimeo",
]);
Object.defineProperties(Vue.prototype, {
  $vimeo: {
    get: function () {
      return vimeoApiInstance;
    },
  },
});

Vue.use(PiniaVuePlugin);
const pinia = createPinia();

// global event bus
const EventBus = new Vue();
Object.defineProperties(Vue.prototype, {
  $eventBus: {
    get: function () {
      return EventBus;
    },
  },
});
VueClipboard.config.autoSetContainer = true;
Vue.use(VueClipboard);
Vue.component("PImg", () => import("@/components/poin-ui/Image.vue"));
Vue.component("PBtn", () => import("@/components/poin-ui/Button.vue"));
Vue.component("PBtnList", () => import("@/components/poin-ui/ButtonList.vue"));
Vue.component("PBtnMore", () => import("@/components/poin-ui/ButtonMore.vue"));
Vue.component("PIcon", () => import("@/components/poin-ui/Icon.vue"));
Vue.component("PAsset", () => import("@/components/poin-ui/Asset.vue"));
Vue.component("PIconBtn", () => import("@/components/poin-ui/IconButton.vue"));
Vue.component("PInput", () => import("@/components/poin-ui/Input.vue"));
Vue.component(
  "PInputMerge",
  () => import("@/components/poin-ui/InputMerge.vue")
);
Vue.component("PTextarea", () => import("@/components/poin-ui/Textarea.vue"));
Vue.component("PSelect", () => import("@/components/poin-ui/Select.vue"));
Vue.component(
  "PSelectSort",
  () => import("@/components/poin-ui/SelectSort.vue")
);
Vue.component("PTable", () => import("@/components/poin-ui/Table.vue"));
Vue.component("PCheckbox", () => import("@/components/poin-ui/Checkbox.vue"));
Vue.component("PRadio", () => import("@/components/poin-ui/Radio.vue"));
Vue.component("PChip", () => import("@/components/poin-ui/Chip.vue"));
Vue.component("PChipIcon", () => import("@/components/poin-ui/ChipIcon.vue"));
Vue.component("PBadge", () => import("@/components/poin-ui/Badge.vue"));
Vue.component(
  "PBadgeDelete",
  () => import("@/components/poin-ui/BadgeDelete.vue")
);
Vue.component("PFileInput", () => import("@/components/poin-ui/FileInput.vue"));
Vue.component(
  "PDatePicker",
  () => import("@/components/poin-ui/DatePicker.vue")
);
Vue.component("PSwitch", () => import("@/components/poin-ui/Switch.vue"));
Vue.component("PSpinner", () => import("@/components/poin-ui/Spinner.vue"));
Vue.component("PTabs", () => import("@/components/poin-ui/Tabs.vue"));
Vue.component("PTip", () => import("@/components/poin-ui/Tip.vue"));
Vue.component("PLink", () => import("@/components/poin-ui/LinkButton.vue"));
Vue.component("PFilter", () => import("@/components/poin-ui/SelectFilter.vue"));
Vue.component(
  "PStickyHeader",
  () => import("@/components/poin-ui/StickyHeader.vue")
);
Vue.component("PDateInput", () => import("@/components/poin-ui/DateInput.vue"));
Vue.component("EmptyBox", () => import("@/components/poin-ui/EmptyBox.vue"));
Vue.component("EmptyCard", () => import("@/components/poin-ui/EmptyCard.vue"));
Vue.component("PModal", () => import("@/components/poin-ui/Modal.vue"));
Vue.component("PLabel", () => import("@/components/poin-ui/Label.vue"));
Vue.component("PLottie", () => import("@/components/poin-ui/Lottie.vue"));
Vue.component(
  "PColorPicker",
  () => import("@/components/poin-ui/ColorPicker.vue")
);
Vue.component(
  "AdminTitle",
  () => import("@/components/campus/admin/AdminTitle.vue")
);
Vue.component(
  "EditorViewer",
  () => import("@/components/common/EditorViewer.vue")
);
Vue.component(
  "InfoVertical",
  () => import("@/components/poin-ui/InfoVertical.vue")
);
Vue.component(
  "InfoHorizon",
  () => import("@/components/poin-ui/InfoHorizon.vue")
);
Vue.component("InfoTitle", () => import("@/components/poin-ui/InfoTitle.vue"));
Vue.component(
  "PInputImage",
  () => import("@/components/poin-ui/InputImage.vue")
);
Vue.component(
  "MonthPicker",
  () => import("@/components/poin-ui/MonthPicker.vue")
);

new Vue({
  router,
  store,
  vuetify,
  i18n,
  pinia,
  render: function (h) {
    return h(App);
  },
}).$mount("#app");
