import globalStore from "@/store";
import { oAuthRedirectUrl } from "@/config";
import isEmpty from "lodash.isempty";
import { MemberStatus } from "@/enums/membersEnum";
import { cookieUtils } from "@/utils";

export const handleOauth = (to, next) => {
  const { params, query } = to;
  const { code, state, naverRedirect, origin } = query;
  const { provider } = params;
  switch (provider) {
    case "naver":
    case "google":
    case "kakao":
    case "facebook":
    case "apple":
      if (naverRedirect) {
        window.location.href = `${oAuthRedirectUrl}?snsType=NAVER&state=${state}`;
        return;
      }
      if (code) {
        return reqLogin({ code, snsType: provider.toUpperCase(), state }, next);
      }
      break;
    case "zoom":
      if (origin) {
        window.location.href = `${origin}/oauth-callback/zoom?code=${code}`;
        return;
      }
      window.opener.onZoomPopupClose(code);
      setTimeout(() => {
        window.close();
      }, 400);
      return { success: true, data: { path: "/", replace: true } };
    // break;
  }
};

export const reqLogin = async (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const loginResult = await globalStore.dispatch("auth/login", data);
      const { state } = data;
      const stateParsed = JSON.parse(atob(decodeURIComponent(state)));
      const nextUrl = stateParsed.nextUrl;
      const campusUuid = stateParsed.campusUuid;
      let result;
      if (campusUuid) {
        const sessionId = cookieUtils.getCookie("poin-msi");
        const userId = globalStore.getters["users/getUserUuid"];
        result = await globalStore.dispatch("campuses/reqGetCampusInfo", {
          campusId: campusUuid,
          slug: "home",
          ...((sessionId || userId) && {
            headers: { "poin-custom": sessionId || userId },
            userId,
          }),
        });
      }
      const campusDomain = result?.data?.domain || "";
      if (loginResult.success && loginResult.authInfo?.user?.uuid) {
        // 기존 경로로 이동.
        resolve({
          success: true,
          data: {
            ...(nextUrl
              ? {
                  path: nextUrl,
                }
              : campusDomain
                ? {
                    name: "campus-home",
                    params: {
                      campusDomain,
                    },
                  }
                : {
                    path: "/",
                  }),
            replace: true,
          },
        });
      } else {
        const isWebview = globalStore.getters["common/isPoinWebview"];
        const cognitoId = isWebview
          ? loginResult.authInfo.cognitoId
          : loginResult.authInfo.identityId?.replace("ap-northeast-2:", "");
        const params = {
          snsType: data.snsType,
          cognitoId,
          email: loginResult.authInfo.email,
          auth: loginResult.authInfo,
          ...(campusDomain && { campusDomain }),
        };
        const name = campusDomain
          ? "campus-join-terms-agree"
          : "join-terms-agree";
        resolve({
          success: true,
          data: {
            name,
            params,
            replace: true,
          },
        });
      }
    } catch (error) {
      reject({ success: false, data: error });
    }
  });
};

/**
 * 각 route별 권한 체크 후 진입 여부 결정
 * next 함수에 넘겨줄 route 반환
 * meta에 memberAuth가 빈객체가 아닌 경우, 권한 체크
 */
export const handleCampusMemberAuth = (to, from, next) => {
  const userId = globalStore.getters["users/getUserUuid"];
  const memberStatus = globalStore.getters["members/getMemberStatus"];
  const notAuthRoutes = ![
    "campus-join-select",
    "campus-staff-join",
    "campus-member-join",
    "campus-join-terms-agree",
    "campus-join-form",
    "campus-join-code",
    "campus-login-select",
    "campus-reset-password",
  ].includes(to.name);
  const needCampusMemberJoin =
    !memberStatus ||
    memberStatus === MemberStatus.DEL ||
    memberStatus === MemberStatus.CANCEL;
  if (userId && notAuthRoutes && needCampusMemberJoin) {
    if (to?.fullPath) {
      globalStore.dispatch("common/setLocationPath", to.fullPath);
    }
    return next({
      name: "campus-member-join",
      params: to.params,
      replace: true,
    });
  }

  let memberAuthMeta = null;
  // memberAuth meta는 부모 route부터 본인 route까지 탐색해
  // 본인 route와 가장 가까운 meta를 적용한다.
  to.matched.forEach((route) => {
    if (!isEmpty(route.meta.memberAuth)) {
      memberAuthMeta = route.meta.memberAuth;
    }
  });

  if (!memberAuthMeta) return next();
  const { role: roleMeta, authority: authorityMeta } = memberAuthMeta;
  const isCampusJoinMember = globalStore.getters["members/isCampusJoinMember"];
  const isCampusOwner = globalStore.getters["campuses/isCampusOwner"];

  // campus member check
  if (!isCampusOwner && !isCampusJoinMember) {
    globalStore.dispatch("common/setAlert", {
      open: true,
      msg: "캠퍼스 멤버만 접근할 수 있는 페이지입니다.",
    });
    next(getCampusHomeRoute());
    return;
  }

  // role && authority code check
  const hasRoleOrAuth = globalStore.getters["members/hasRoleOrAuth"];
  if (
    !hasRoleOrAuth({
      roleCd: roleMeta,
      authority: authorityMeta,
      staffOnly: memberAuthMeta.staffOnly,
    })
  ) {
    globalStore.dispatch("common/setAlert", {
      open: true,
      title: "접근 불가",
      msg: "접근 권한이 없는 페이지입니다.",
    });
    if (
      to.matched.some((r) => r.meta.isAdmin) &&
      globalStore.getters["members/isStaff"]
    ) {
      next(getCampusHomeRoute("campus-admin-dashboard"));
      return;
    }
    next(getCampusHomeRoute());
    return;
  }

  return next();
};

export const loginPathBeforeEnter = (_, __, next) => {
  const authenticated = globalStore.getters["auth/getAuthenticated"];
  if (authenticated) {
    const campusOnly = globalStore.getters["common/campusOnly"];
    if (campusOnly) {
      next(getCampusHomeRoute());
    } else {
      const savedPath = globalStore.getters["common/getLocationPath"];
      next(savedPath || "/");
    }
  } else {
    next();
  }
};

export const getCampusHomeRoute = (name = "campus-home") => {
  let campusDomain = globalStore.getters["campuses/getCampusDomain"];
  let campusUuid = globalStore.getters["campuses/getCampusUuid"];
  const route = {
    name,
  };
  if (campusDomain) {
    route.params = {
      campusDomain,
    };
    return route;
  }

  if (campusUuid) {
    route.params = "home";
    route.query = {
      campusUuid,
    };
    return route;
  }

  return route;
};

const getBackRoute = (from) => {
  return {
    name: from.name,
    meta: from.meta,
    path: from.path,
    query: from.query,
  };
};

export const checkShutdown = () => {
  const { shutdownAfter, shutdownLocation } =
    globalStore.getters["configure/getSysConfig"] || {};
  const shutdownYn = Date.now() > shutdownAfter;
  if (shutdownYn && shutdownLocation) {
    window.location.replace(shutdownLocation);
  }
};
