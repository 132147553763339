import globalStore from "@/store";
import { webBaseUrl } from "@/config";
import { naverWcs } from "@/router/utils/naver-wcs";
import { loginPathBeforeEnter } from "@/router/utils/auth-utils";
import { useAlert } from "@/stores/alert";
import SnsSelect from "@/components/common/SnsSelect.vue";

export const poincampusRoutes = {
  path: "/",
  component: () => import("@/views/poincampus"),
  children: [
    {
      path: "",
      name: "poin-home",
      component: () => import("@/views/poincampus/Home.vue"),
    },
    {
      path: "makers",
      name: "poin-makers",
      component: () => import("@/views/poincampus/CampusMakers.vue"),
    },
    {
      path: "supporters",
      name: "poin-supporters",
      component: () => import("@/views/poincampus/Supporters.vue"),
    },
    {
      path: "features",
      name: "poin-features",
      component: () => import("@/views/poincampus/Features.vue"),
    },
    {
      path: "pricing",
      name: "poin-pricing",
      component: () => import("@/views/poincampus/Pricing.vue"),
    },
    {
      path: "addons",
      name: "poin-addons",
      component: () => import("@/views/poincampus/Addons.vue"),
    },
    {
      path: "list",
      name: "poin-campus-list",
      meta: { authRequired: true },
      beforeEnter(_, __, next) {
        const origin = window.location.origin;
        if (webBaseUrl === origin) {
          next({ name: "campuses" });
        } else {
          next({
            name: "auth-bridge",
            query: {
              next: `${webBaseUrl}/my/campuses`,
            },
          });
        }
      },
    },
    {
      path: "help",
      component: () => import("@/views/poincampus/help"),
      children: [
        {
          path: "notice",
          name: "notice",
          component: { template: "<div>NOTICE</div>" },
          beforeEnter() {
            globalStore.dispatch("common/setLoading", true);
            window.location.href = "https://poinguide.oopy.io/auth/update";
          },
        },
        {
          path: "terms",
          redirect: { name: "terms" },
        },
        {
          path: "privacy",
          redirect: { name: "help-privacy" },
        },
        {
          path: "faq",
          name: "faq",
          component: { template: "<div>FAQ</div>" },
          beforeEnter() {
            globalStore.dispatch("common/setLoading", true);
            window.location.href = "https://poinguide.oopy.io/auth/faq";
          },
        },
        {
          path: "ask",
          component: () => import("@/views/poincampus/help/ask"),
          children: [
            {
              path: "",
              name: "ask",
              component: () => import("@/views/poincampus/help/ask/Ask"),
            },
            {
              path: "write",
              name: "help-ask-write",
              meta: { authRequired: true },
              component: () => import("@/views/poincampus/help/ask/Write"),
            },
          ],
        },
        {
          path: "terms/sale",
          redirect: { name: "terms-of-sales" },
        },
        {
          path: "terms/purchase",
          redirect: { name: "terms-of-purchase" },
        },
        {
          path: "terms/partner",
          redirect: { name: "terms-of-partner" },
        },
      ],
    },
    {
      path: "policy",
      component: () => import("@/views/poincampus/policy"),
      children: [
        {
          path: "",
          name: "policy",
          redirect: { name: "terms" },
        },
        {
          path: "terms",
          name: "terms",
          component: () => import("@/views/poincampus/policy/Provision"),
        },
        {
          path: "privacy",
          name: "help-privacy",
          component: () => import("@/views/poincampus/policy/Privacy"),
        },
        {
          path: "terms/sale",
          name: "terms-of-sale",
          component: () => import("@/views/poincampus/policy/Sales"),
        },
        {
          path: "terms/purchase",
          name: "terms-of-purchase",
          component: () => import("@/views/poincampus/policy/Purchase"),
        },
        {
          path: "terms/partner",
          name: "terms-of-partner",
          component: () => import("@/views/poincampus/policy/Partner"),
        },
      ],
    },
    {
      path: "register",
      meta: { authRequired: true },
      component: () => import("@/views/register"),
      children: [
        {
          path: "",
          name: "campus-register",
          meta: { authRequired: true },
          component: () => import("@/views/register/Home"),
        },
        {
          path: "complete",
          name: "register-complete",
          meta: { authRequired: true },
          component: () => import("@/views/register/Complete"),
          beforeEnter(_, __, next) {
            naverWcs(1);
            next();
          },
        },
      ],
    },
    {
      path: "join",
      component() {
        return import("@/views/join");
      },
      children: [
        {
          path: "",
          name: "join-select",
          component: {
            data() {
              return {
                useType: "join",
              };
            },
            extends: SnsSelect,
          },
        },
        {
          path: "agreement",
          name: "join-terms-agree",
          component: () => import("@/views/join/TermsAgree"),
          props: true,
        },
        {
          path: "form",
          name: "join-form",
          component: () => import("@/views/join/Form"),
          props: true,
        },
        {
          path: "code",
          name: "join-code",
          component: () => import("@/views/join/Code"),
          props: true,
        },
      ],
      beforeEnter(to, from, next) {
        loginPathBeforeEnter(to, from, next);
      },
    },
    {
      path: "login",
      component: () => import("@/views/login/Main.vue"),
      children: [
        {
          path: "",
          name: "login-select",
          component: {
            data() {
              return {
                useType: "login",
              };
            },
            extends: SnsSelect,
          },
        },
        {
          path: "reset-password",
          name: "reset-password",
          component: () => import("@/views/login/Reset-Password.vue"),
        },
      ],
      beforeEnter(to, from, next) {
        loginPathBeforeEnter(to, from, next);
      },
    },
    {
      path: "my",
      meta: { authRequired: true },
      component: () => import("@/views/my"),
      children: [
        {
          path: "",
          name: "my",
          redirect: { name: "campuses" },
        },
        {
          path: "campuses",
          name: "campuses",
          meta: { authRequired: true },
          component: () => import("@/views/my/Campuses"),
        },
        {
          path: "notification",
          name: "notification",
          meta: { authRequired: true },
          component: () => import("@/views/my/Notification"),
        },
        {
          path: "account",
          name: "account",
          meta: { authRequired: true },
          component: () => import("@/views/my/Account"),
        },
      ],
    },
    {
      path: "cancel/:campusId/:purchaseId",
      meta: { authRequired: true },
      component: () => import("@/views/cancel"),
      children: [
        {
          path: "",
          meta: { authRequired: true },
          name: "purchase-cancel",
          component: () => import("@/views/cancel/Cancel"),
        },
        {
          path: "complete",
          meta: { authRequired: true },
          name: "purchase-cancel-complete",
          component: () => import("@/views/cancel/Complete"),
        },
      ],
    },
    {
      path: "certificates/:productId/:memberId",
      meta: { authRequired: true, customLayout: true },
      component: () => import("@/views/certificates"),
    },
    {
      path: "poincamp",
      name: "poincamp",
      component: { template: "<div>PoinCampus</div>" },
      beforeEnter(to, before, next) {
        const alert = useAlert();
        alert.open({
          icon: "info",
          title: "서비스 준비 중입니다.",
          msg: "빠른 시일 내에 찾아뵙겠습니다.",
        });
        return;
      },
    },
    {
      path: "poinstar",
      name: "poinstar",
      component: { template: "<div>PoinCampus</div>" },
      beforeEnter(to, before, enxt) {
        const alert = useAlert();
        alert.open({
          icon: "info",
          title: "서비스 준비 중입니다.",
          msg: "빠른 시일 내에 찾아뵙겠습니다.",
        });
        return;
      },
    },
    {
      path: "blog",
      name: "blog",
      component: { template: "<div>PoinCampus</div>" },
      beforeEnter() {
        globalStore.dispatch("common/setLoading", true);
        window.location.href = "https://blog.naver.com/poincampus";
      },
    },
  ],
};
