export const campusMakers = [
  {
    title: "캠퍼스메이커 소개",
    description: "플랫폼을 구축해서 콘텐츠 사업을 시작하세요.",
    link: { name: "poin-makers" },
    isExternal: false,
  },
  {
    title: "기능",
    description: "플랫폼 사업을 위한 모든 기능을 담았습니다.",
    link: { name: "poin-features" },
    isExternal: false,
  },
  {
    title: "요금 플랜",
    description: "나만의 캠퍼스에 맞는 요금 플랜을 선택해보세요.",
    link: { name: "poin-pricing" },
    isExternal: false,
  },
  {
    title: "부가서비스",
    description: "부가 서비스를 통해 다양한 기능을 경험해보세요.",
    link: { name: "poin-addons" },
    isExternal: false,
  },
  {
    title: "업데이트 소식",
    description: "캠퍼스메이커의 업데이트 소식을 확인해보세요.",
    link: { name: "notice" },
    isExternal: true,
  },
  {
    title: "자주 묻는 질문",
    description: "캠퍼스메이커의 자주 묻는 질문을 확인해보세요.",
    link: { name: "faq" },
    isExternal: true,
  },
];

export const supporters = [
  {
    title: "성장 지원 소개",
    description: "여러분의 교육 비즈니스를 확장시키고 싶으신가요?",
    link: { name: "poin-supporters" },
    isExternal: false,
  },
  {
    title: "PoinCAMP",
    description: "교육 사업 선두주자들의 노하우와 전략을 공유받고 싶으신가요?",
    link: { name: "poincamp" },
    isExternal: false,
  },
  {
    title: "PoinSTAR",
    description: "교육 사업들의 열정적인 네트워크에 들어오고 싶으신가요?",
    link: { name: "poinstar" },
    isExternal: false,
  },
  {
    title: "블로그",
    description: "교육 사업에 대한 다양한 정보를 확인하고 싶으신가요?",
    link: { name: "blog" },
    isExternal: true,
  },
];

export const communities = [
  {
    title: "공지사항",
    description: "포인캠퍼스의 공지사항을 확인해보세요.",
    link: { name: "notice" },
    isExternal: true,
  },
  {
    title: "고객센터",
    description: "궁금한 점이 있으시다면 언제든지 문의해주세요.",
    link: { name: "ask" },
    isExternal: false,
  },
];
