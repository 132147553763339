<script setup>
import {
  computed,
  getCurrentInstance,
  onBeforeUnmount,
  onMounted,
  ref,
  watch,
} from "vue";
import { useRoute, useRouter } from "vue-router/composables";
import { useI18n } from "vue-i18n-composable";
import isEmpty from "lodash.isempty";
import { useWindowFocus } from "@vueuse/core";

import { isDev } from "@/config";
import { getFirebaseToken } from "@/firebase";
import { useStore } from "@/store";
import { useConfirm } from "@/stores/confirm";
import { useAlert } from "@/stores/alert";
import { useToast } from "@/stores/toast";
import { checkAppVersion } from "@/utils/app-version";
import IndexedDBUtil from "@/utils/IndexedDBUtil";

import Landing from "@/views/landing";
import Web from "@/components/layout/Web.vue";
import Webview from "@/components/layout/Webview.vue";
import Pwa from "@/components/common/Pwa.vue";
import NotificationBar from "@/components/landing/NotificationBar.vue";
import PConfirm from "@/components/poin-ui/Confirm.vue";
import PAlert from "@/components/poin-ui/Alert.vue";
import MultiVideoProgressPopup from "@/components/common/MultiVideoProgressPopup.vue";

const focused = useWindowFocus();

const { t } = useI18n();
const route = useRoute();
const router = useRouter();
const store = useStore();
const confirm = useConfirm();
const alert = useAlert();
const toast = useToast();
const dbUtil = new IndexedDBUtil("PoinCampus", window.location.origin);

const pending = ref(false);
const bgOpacity = ref(0);
/* confirm */
const confirmOpen = ref(false);
const confirmIcon = ref("alert");
const confirmTitle = ref("");
const confirmMsg = ref("");
const checkboxText = ref("");
const confirmCallback = ref(() => {
  confirmOpen.value = false;
});
const btnTxtConfirm = ref("확인");
const btnTxtCancel = ref("취소");
const confirmReverse = ref(false);
const confirmLoading = ref(false);
const alertOpen = ref(false);
const alertTitle = ref("");
const alertMsg = ref("");
const onAlertOk = ref(() => {
  alertOpen.value = false;
});
const alertLoading = ref(false);
const alertIcon = ref("alert");
const alertLottie = ref("");
const csLink = ref(false);
const alertBtnText = ref("확인");
const snackbar = ref(false);
const snackbarText = ref("저장을 완료했습니다.");
const timeout = ref(3000);
const headerHeight = ref(0);

const isMobile = computed(() => store.getters["common/isMobile"]);
const isWebview = computed(() => store.getters["common/isWebview"]);
const isLanding = computed(() => store.getters["common/isLanding"]);
const isWeb = computed(() => store.getters["common/isWeb"]);
const userUuid = computed(() => store.getters["users/getUserUuid"]);
const loading = computed(() => store.getters["common/loading"]);
const notification = computed(() => store.getters["admins/getNotificationBar"]);
const isPoinWebview = computed(() => store.getters["common/isPoinWebview"]);
const getVideoPanel = computed(() => store.getters["common/getVideoPanel"]);

const showNotificationBar = computed(() => {
  if (route.name !== "poin-home") {
    return false;
  }
  if (isEmpty(notification)) {
    return false;
  }
  if (!notification.value?.subject) {
    return false;
  }
  return true;
});
const notificationLabel = computed(() => {
  return notification.value?.subject || "";
});
const notificationUrl = computed(() => {
  return notification.value?.conts || "";
});

const closeAlert = () => {
  alertOpen.value = false;
  window.setTimeout(() => {
    alertIcon.value = "alert";
    alertTitle.value = "";
    alertMsg.value = "";
    alertBtnText.value = "확인";
    csLink.value = false;
    alertLottie.value = "";
    onAlertOk.value = () => {};
    alert.close();
  }, 200);
};

const closeConfirm = () => {
  confirmOpen.value = false;
  window.setTimeout(() => {
    confirmIcon.value = "";
    confirmTitle.value = "";
    confirmMsg.value = "";
    btnTxtConfirm.value = "";
    btnTxtCancel.value = "";
    confirmReverse.value = false;
    confirmCallback.value = () => {
      confirmOpen.value = false;
    };
    confirm.close();
  }, 200);
};

const setConfirm = (confirm) => {
  confirmIcon.value = confirm.icon;
  confirmTitle.value = confirm.title;
  confirmMsg.value = confirm.msg;
  checkboxText.value = confirm.checkboxText;
  btnTxtConfirm.value = confirm.btnTxtConfirm || "확인";
  btnTxtCancel.value = confirm.btnTxtCancel || "취소";
  confirmReverse.value = confirm.reverse;
  confirmCallback.value = async (yn, checked) => {
    if (confirm.callback) {
      confirmLoading.value = true;
      await confirm.callback(yn, checked);
      confirmLoading.value = false;
    }
    if (confirm.noClose && yn) {
      return;
    }
    closeConfirm();
  };
  confirmOpen.value = true;
};

watch(
  computed(() => confirm.isOpen),
  (isOpen) => {
    if (isOpen) {
      confirmIcon.value = confirm.state.icon;
      confirmTitle.value = confirm.state.title;
      confirmMsg.value = confirm.state.msg;
      btnTxtConfirm.value = confirm.state.btnTxtConfirm || "확인";
      btnTxtCancel.value = confirm.state.btnTxtCancel || "취소";
      confirmReverse.value = confirm.state.reverse;
      confirmCallback.value = async (yn, checked) => {
        if (confirm.state.callback) {
          confirmLoading.value = true;
          await confirm.state.callback(yn, checked);
          confirmLoading.value = false;
        }
        if (confirm.state.noClose && yn) {
          return;
        }
        closeConfirm();
      };
      confirmOpen.value = true;
    } else {
      closeConfirm();
    }
  }
);

watch(
  computed(() => alert.isOpen),
  (isOpen) => {
    if (isOpen) {
      alertIcon.value = alert.state.icon || "alert";
      alertTitle.value = alert.state.title;
      alertMsg.value = alert.state.msg;
      alertBtnText.value = alert.state.btnText || "확인";
      csLink.value = alert.state.csLink || false;
      alertLottie.value = alert.state.lottie || "";
      onAlertOk.value = async (confirm) => {
        if (alert.state.callback) {
          alertLoading.value = true;
          await alert.state.callback(confirm);
          alertLoading.value = false;
        }
        closeAlert();
      };
      alertOpen.value = true;
    } else {
      closeAlert();
    }
  }
);

watch(
  computed(() => toast.state),
  (newValue) => {
    if (newValue.open) {
      snackbarText.value = newValue.text;
      snackbar.value = true;
    }
  },
  { deep: true }
);

watch(snackbar, (value) => {
  if (!value) {
    toast.close();
  }
});

watch(isMobile, (isMobile) => {
  headerHeight.value = isMobile ? 56 : 64;
});

const setWindowWidth = (params) =>
  store.dispatch("common/setWindowWidth", params);
const reqGetPlanList = (params) =>
  store.dispatch("plans/reqGetPlanList", params);
const reqGetNotificationBar = (params) =>
  store.dispatch("admins/reqGetNotificationBar", params);
const checkLocalAuth = (params) =>
  store.dispatch("auth/checkLocalAuth", params);

function onResize() {
  setWindowWidth(window.innerWidth);
}
function initWebviewRouter() {
  if (window.JsBridge) {
    window.loadUrl = (url) => {
      router.push(url);
    };
  }
}
async function getFbToken() {
  if (!isPoinWebview.value) {
    if ("Notification" in window) {
      const permission = await Notification.requestPermission();
      if (permission === "granted") {
        const token = await getFirebaseToken();
        return token;
      }
    }
  }
}
function invalidSrc(src) {
  const blackList = [
    "https://videodev.poincampus.com",
    "https://video.poincampus.com",
    "https://player.vimeo.com",
  ];
  return blackList.findIndex((b) => src.includes(b)) > -1;
}
function initObserver() {
  const iframeAddObserver = new MutationObserver(function (mutations) {
    mutations.forEach(function (mutation) {
      if (
        mutation.addedNodes &&
        mutation.addedNodes[0] &&
        mutation.addedNodes[0].nodeName === "IFRAME" &&
        invalidSrc(mutation.addedNodes[0].src)
      ) {
        console.log("invalid iframe inserted!");
        console.log(mutation.addedNodes[0].src);
        mutation.addedNodes[0].remove();
      }
    });
  });
  iframeAddObserver.observe(document, {
    attributes: true,
    subtree: true,
    childList: true,
    attributeFilter: ["src"],
  });
}

const initDB = async () => {
  try {
    await dbUtil.openDB();
    dbUtil.clearAllData();
  } catch (error) {
    console.log(error);
  }
};

initWebviewRouter();
reqGetPlanList();
initObserver();
reqGetNotificationBar();
initDB();
//firebase Token을 생성하고 sessionStorage에 저장
const setFbToken = async () => {
  const token = await getFbToken();
  window.sessionStorage.setItem("firebaseToken", token);
};
const prevToken = window.sessionStorage.getItem("firebaseToken");
if (prevToken) {
  window.sessionStorage.setItem("firebaseToken", prevToken);
} else {
  setFbToken();
}

onMounted(() => {
  const vm = getCurrentInstance();
  vm.proxy.$eventBus.$on(
    "confirm",
    ({
      open = false,
      icon = "alert",
      title,
      msg,
      callback,
      checkboxText = "",
      btnTxtConfirm = "확인",
      btnTxtCancel = "취소",
      reverse = false,
      noClose = false,
    }) => {
      if (open) {
        setConfirm({
          icon,
          title,
          msg,
          callback,
          btnTxtConfirm,
          btnTxtCancel,
          checkboxText,
          reverse,
          noClose,
        });
        confirm.open({
          icon,
          title,
          msg,
          callback,
          btnTxtConfirm,
          btnTxtCancel,
          checkboxText,
          reverse,
          noClose,
        });
      } else {
        closeConfirm();
      }
    }
  );
  vm.proxy.$eventBus.$on(
    "alert",
    ({
      open = false,
      icon = "alert",
      title = "",
      msg = "",
      lottie = "",
      callback,
      btnText = "확인",
      csLink = false,
    }) => {
      if (open) {
        alert.open({
          icon,
          title,
          msg,
          lottie,
          callback,
          btnText,
          csLink,
        });
      } else {
        closeAlert();
      }
    }
  );
  vm.proxy.$eventBus.$on("toast", (text = t("toastMsg.save")) => {
    toast.open(text);
  });
  vm.proxy.$eventBus.$on("pending", ({ yn, opacity = 0 }) => {
    pending.value = yn;
    bgOpacity.value = opacity;
  });
  vm.proxy.$eventBus.$on("gtag", ({ name, category, label, value }) => {
    if (isDev) {
      return;
    }
    window.gtag("event", name, {
      event_category: category,
      ...(label && { event_label: label }),
      value: value,
    });
  });
  onResize();
  window.addEventListener("resize", onResize);
  window.addEventListener("storage", checkLocalAuth);
  if (window.navigator.userAgent.includes("Window")) {
    document.body.classList.add("ua-window");
  }
  if (window.navigator.userAgent.indexOf("Firefox") !== -1) {
    document.body.classList.add("ua-firefox");
  }
});
// window.addEventListener("vite:preloadError", (event) => {
//   window.location.reload();
// });
onBeforeUnmount(() => {
  window.removeEventListener("resize", onResize);
  window.removeEventListener("storage", checkLocalAuth);
});

const updateCredentials = async () => {
  const prevAuth = store.getters["auth/getAuth"];
  const localAuth = JSON.parse(
    window.localStorage.getItem("auth") ||
      window.sessionStorage.getItem("auth") ||
      "{}"
  );
  if (localAuth.identityId && localAuth.identityId === prevAuth.identityId) {
    store.dispatch("auth/setAuth", {
      snsType: localAuth.snsType,
      auth: localAuth,
    });
    await store.dispatch("users/getUserFromCognitoId", {
      setAccInfo: true,
      cognitoId: localAuth.identityId.replace("ap-northeast-2:", ""),
      sigYn: false,
      accessKeyId: store.getters["auth/getCredentials"]?.accessKeyId,
    });
  }
};

watch(focused, async (focused) => {
  if (focused) {
    // checkAppVersion();
    const isWebview = store.getters["common/isPoinWebview"];
    const isAdmin = store.getters["auth/getAuth"]?.isAdmin;
    if (!isWebview && !isAdmin) {
      updateCredentials();
    }
  }
});
</script>

<template>
  <v-app id="app">
    <template v-if="isWebview">
      <webview class="webview" />
    </template>
    <template v-else-if="isLanding">
      <landing />
    </template>
    <template v-else-if="isWeb">
      <notification-bar
        v-if="showNotificationBar"
        :label="notificationLabel"
        :url="notificationUrl"
      />
      <web class="web" />
      <pwa />
    </template>
    <!-- just cover screen except header -->
    <v-overlay
      :value="pending"
      color="black"
      :opacity="bgOpacity"
      z-index="9998"
      :style="`
        height: calc(100dvh - ${headerHeight});
        top: ${headerHeight};
      `"
    >
      <v-progress-circular indeterminate color="primary" />
    </v-overlay>

    <!-- hide screen  -->
    <v-overlay :value="loading" color="white" z-index="9999" opacity="1">
      <v-progress-circular indeterminate color="primary" />
    </v-overlay>
    <p-confirm
      :btn-txt-cancel="btnTxtCancel"
      :btn-txt-confirm="btnTxtConfirm"
      :icon="confirmIcon"
      :is-open="confirmOpen"
      :message="confirmMsg"
      :title="confirmTitle"
      :loading="confirmLoading"
      :reverse="confirmReverse"
      :checkbox-text="checkboxText"
      @confirm="confirmCallback"
    />
    <p-alert
      :alert="alertOpen"
      :title="alertTitle"
      :icon="alertIcon"
      :lottie="alertLottie"
      :msg="alertMsg"
      :cs-link="csLink"
      :btn-text="alertBtnText"
      :loading="alertLoading"
      @submit="onAlertOk"
    />
    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      app
      bottom
      text
      dark
      class="mb-3"
    >
      <span class="text-body-2-medium white--text text-left">
        {{ snackbarText }}
      </span>
      <template v-slot:action>
        <p-icon
          size="24"
          icon="Close/White"
          @click="snackbar = false"
          class="cursor-pointer"
        />
      </template>
    </v-snackbar>
    <multi-video-progress-popup v-if="userUuid" v-show="getVideoPanel" />
  </v-app>
</template>

<style>
@import "style/vuetify.min.css";
@import "style/import-font.css";
@import "style/reset.css";
@import "style/global.css";
</style>

<style lang="scss">
@import "@/style/styles.scss";
// snack-bar
.v-snack__wrapper.theme--dark {
  background-color: rgba(0, 0, 0, 0.75);
}
.v-snack__content {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 16px;
}
</style>

<style scoped>
#app {
  position: relative;
}

#app > .web {
  height: 100%;
  width: 100%;
}
#app > .webview {
  height: 100%;
  width: 100%;
}
</style>
